<template>
  <el-container>
    <!-- 未授权 -->
    <el-main v-if="!is_authorization">
      <div class="blodTitle">公众号</div>
      <img :style="{ marginBottom: isShouQuan ? '' : '20px' }" :src="imgUrlHead + 'officialAccountSet.png'" alt="" />
      <div class="isAuthorization">
        <div class="Authorizations" v-for="(item, index) in 2" :key="index">
          <div class="top">{{ index == 0 ? '未有公众号' : '已有公众号' }}</div>
          <div class="mid">
            {{ index == 0 ? '登录微信公众平台,申请公众号。' : '绑定授权后,您可以在这里管理公众号菜单,自定义菜单中的功能' }}
          </div>
          <div class="bot" @click="regiOrGrant(index)">{{ index == 0 ? '申请公众号' : '立即绑定' }}</div>
        </div>
      </div>

      <!--  -->
      <div class="blodTitle">服务号与订阅号区别</div>
      <div class="descInfo">
        <div class="textDesc">
          <p>1.服务号功能</p>
          <p>公众平台服务号,是公众平台的一种账号类型,旨在为用户提供服务</p>
          <p>(1) 1个月(自然月)内仅可以发送4条群发消息</p>
          <p>(2) 发给订阅用户(粉丝)的消息,会显示在对方的聊天列表中,相对应微信的首页</p>
          <p>(3) 服务号会在订阅用户(粉丝)的通讯录中.通信录中有一个公众号的文件夹,点开可以查看所有服务号</p>
          <p>(4) 服务号可申请自定义菜单</p>
          <p>2.订阅号功能</p>
          <p>公众平台订阅号,是公众平台的一种账号类型,旨在为用户提供信息</p>
          <p>(1) 每天(24小时内)可以发送一条群发消息</p>
          <p>(2) 发给订阅用户(粉丝)的消息,将会显示在对方的"订阅号"文件夹中.点击两次才可以打开</p>
          <p>(3) 在订阅用户(粉丝)的通讯录中,订阅号将被放入订阅号文件夹中</p>
        </div>
        <el-table :data="tableList" height="100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column prop="title" align="center" width="150px"></el-table-column>
          <el-table-column label="普通订阅号" align="center" width="150px">
            <template v-slot="{ row }">
              <i v-if="row.ordinary_one" class="el-icon-check isHave"></i>
            </template>
          </el-table-column>
          <el-table-column label="认证订阅号" align="center" width="150px">
            <template v-slot="{ row }">
              <i v-if="row.authentication_one" class="el-icon-check isHave"></i>
            </template>
          </el-table-column>
          <el-table-column label="普通服务号" align="center" width="150px">
            <template v-slot="{ row }">
              <i v-if="row.ordinary_two" class="el-icon-check isHave"></i>
            </template>
          </el-table-column>
          <el-table-column label="认证服务号" align="center" width="150px">
            <template v-slot="{ row }">
              <i v-if="row.authentication_two" class="el-icon-check isHave"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <!-- 已授权 -->
    <OfficialAccountInfo ref="OfficialAccountInfo" v-if="is_authorization"></OfficialAccountInfo>
  </el-container>
</template>

<script>
import { imgUrlHead } from '@/util/config';
import OfficialAccountInfo from './officialAccountInfo.vue';
export default {
  components: {
    OfficialAccountInfo,
  },
  data() {
    return {
      imgUrlHead,
      // -1-接口未调用成功 0-未授权 1-已授权
      is_authorization: -1,
      data_info: {},
      tableList: [
        { title: '消息自动回复', ordinary_one: 1, authentication_one: 1, ordinary_two: 1, authentication_two: 1 },
        { title: '自定义菜单', ordinary_one: 0, authentication_one: 1, ordinary_two: 1, authentication_two: 1 },
        { title: '群发/定时群发', ordinary_one: 0, authentication_one: 1, ordinary_two: 0, authentication_two: 1 },
        { title: '高级客户管理', ordinary_one: 0, authentication_one: 0, ordinary_two: 0, authentication_two: 1 },
        { title: '参数二维码', ordinary_one: 0, authentication_one: 0, ordinary_two: 0, authentication_two: 1 },
        { title: '模板消息', ordinary_one: 0, authentication_one: 0, ordinary_two: 0, authentication_two: 1 },
      ],
    };
  },
  updated() {
    this.getInfo();
  },
  created() {
    this.getInfo();
  },
  methods: {
    // 注册公众号或者授权平台
    regiOrGrant(index) {
      if (index === 0) {
        window.open('https://mp.weixin.qq.com/');
      } else if (index === 1) {
        if (this.dataInfo && this.dataInfo.appstatus >= 1) {
          this.$message.warning('您已上传过公众号，请联系客服进行更改');
          return;
        }
        let that = this;
        this.$axios
          .post(this.$api.set.officialAccountSet.getPreAuthorizationUrl, {
            from: 0,
          })
          .then(res => {
            if (res.code == 0) {
              that
                .$confirm('是否完成授权', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then(() => {
                  this.getInfo();
                })
                .catch(() => {});
              window.open(res.result, '_blank');
            }
          });
      }
    },
    // 获取初始状态值
    getInfo() {
      let that = this;
      this.$axios.post(this.$api.set.officialAccountSet.getWechat, {}).then(res => {
        if (res.code == 0) {
          this.is_authorization = res.result ? 1 : 0;
          this.data_info = res.result || {};
          if (this.is_authorization === 1) {
            this.$nextTick(() => {
              that.$refs.OfficialAccountInfo.dataInfo = that.data_info;
              that.$refs.OfficialAccountInfo.getMenu();
              that.$refs.OfficialAccountInfo.siteMiniAppInfo();
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  background: #fff;
}
.el-main {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;
    .subTitle {
      color: #5654f3;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
  }
  & > img {
    width: 100%;
  }
  .isAuthorization {
    width: 100%;
    display: flex;
    & > div {
      width: 380px;
      padding: 20px;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #f7f8fa;
      margin-right: 20px;
    }
    .top {
      font-size: 18px;
      color: #333;
      text-align: center;
      font-weight: bold;
    }
    .mid {
      width: 100%;
      font-size: 14px;
    }
    .bot {
      width: 228px;
      height: 32px;
      background: #00ca5a;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      align-self: center;
    }
  }
}

.descInfo {
  display: flex;
  justify-content: space-between;
  .textDesc {
    width: calc(100% - 752px);
    flex-shrink: 1;
    p {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  .isHave {
    color: #66b1ff;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
