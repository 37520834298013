<template>
  <el-main>
    <!-- 显示授权信息 -->
    <div class="showInfo">
      <div class="infoTitle">公众号授权状态:</div>
      <div class="infoBox">
        <div class="status">
          已授权
          <el-button type="text" @click="lookMoreFlag = true">查看更多</el-button>
        </div>
        <div class="tips">当前店铺已经获得了该公众号的所有接口权限,可以正常对接微信,如果在使用中发现接口异常,请联系客服</div>
      </div>
    </div>
    <!-- 自定义菜单 -->
    <div class="blodTitle">
      自定义菜单
      <span class="subTitle" @click="$router.push('/shopManagement/wechatEcology/wxsubscribe')">设置订阅信息</span>
    </div>

    <!-- 编辑区 -->
    <div class="editingArea">
      <div class="phoneArea">
        <div class="menuBox">
          <!-- 循环一级菜单 -->
          <div :class="['menuItem', firstMenuActive === index && !isSecondMenu ? 'menuActive' : '']"
            v-for="(item, index) in button" :key="index" @click="activeFirst(index)">
            {{ item.name }}
            <div class="childMenu" @click.stop="() => {}" v-if="firstMenuActive === index"
              :style="{ top: secondMenuTop, width: secondMenuWidth }">
              <div :class="['menuItem', secondMenuActive === i && isSecondMenu ? 'menuActive' : '']"
                @click="activeSecond(i)" v-for="(child, i) in item.sub_button" :key="i">{{ child.name }}</div>
              <div @click="addSecondMenu" v-if="item.sub_button.length < 5"><i class="el-icon-plus"></i></div>
            </div>
          </div>
          <div class="addMenuBtn" v-if="button.length < 3" @click="addFirstMenu">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <div class="operationArea">
        <div class="menuTop" v-if="button.length && firstMenuActive >= 0">
          <div class="menuName">{{ nowEditMenu.name }}</div>
          <el-button type="text" @click="deleteMenu">删除菜单</el-button>
        </div>
        <div class="menuBot" v-if="button.length && firstMenuActive >= 0">
          <div class="tips mt10"
            v-if="!isSecondMenu && button[firstMenuActive] && button[firstMenuActive].sub_button.length">
            已添加子菜单，仅可设置菜单名称。</div>
          <!-- 修改菜单名称 -->
          <div class="menuItem">
            <div class="title">菜单名称：</div>
            <el-input v-model="nowEditMenu.editName" @input="checkLength"></el-input>
            <div class="tips ml10">仅支持中英文和数字，字数不超过{{ isSecondMenu ? 8 : 4 }}个汉字或{{ isSecondMenu ? 16 : 8 }}个字母</div>
          </div>
          <!-- 回复内容 -->
          <div class="menuItem"
            v-if="isSecondMenu || (button[firstMenuActive] && !button[firstMenuActive].sub_button.length)">
            <div class="title">回复内容：</div>
            <div class="right">
              <el-radio-group v-model="nowEditMenu.type">
                <el-radio label="click">发送消息</el-radio>
                <el-radio label="view">跳转H5</el-radio>
                <el-radio label="miniprogram">跳转小程序店铺</el-radio>
              </el-radio-group>
              <div class="area">
                <div class="click" v-if="nowEditMenu.type == 'click'">
                  消息内容：
                  <el-input :maxlength="60" type="textarea" :rows="5" placeholder="请输入内容" v-model="nowEditMenu.key">
                  </el-input>
                </div>
                <div class="view" v-if="nowEditMenu.type == 'view'">
                  <div>订阅者点击该菜单会跳到以下链接</div>
                  <div>
                    页面地址：
                    <el-input v-model="nowEditMenu.h5Url"></el-input>
                  </div>
                </div>
                <div class="miniprogram" v-if="nowEditMenu.type == 'miniprogram'">
                  <!-- 显示关联小程序进度 -->
                  <CdSteps :active="nowEditMiniStep" v-if="nowEditMiniStep < 2">
                    <Steps title="授权发布小程序" btn_txt="去授权" btn_success_txt="已授权" description="可前往 '店铺>微信生态>小程序发布'进行发布"
                      @btnClickFn="FirstStepFn"></Steps>
                    <Steps title="关联小程序" btn_txt="去关联" btn_success_txt="已关联" @btnClickFn="SecondStepFn">
                      <template #description>
                        可前往 "微信公众号>广告与服务>小程序管理>添加>关联小程序" 关联
                        <el-button type="text" @click="toLook">查看指引</el-button>
                      </template>
                    </Steps>
                  </CdSteps>
                  <!-- 关联完成后 显示小程序信息 -->
                  <div class="showMiniAppInfo" v-else>
                    <div>当前关联的小程序:</div>
                    <div class="appInfo">
                      <img :src="miniAppLogo" alt="" />
                      <span>{{ miniAppInfo.nickname }}</span>
                    </div>
                    <div>
                      <sup style="color: red">*</sup>
                      跳转小程序:{{ nowEditMenu.pagepath || '--' }}
                    </div>
                    <div>
                      <el-button @click="choosePage">选择小程序路径</el-button>
                    </div>
                    <div>
                      <sup style="color: red">*</sup>
                      备用网页url:
                    </div>
                    <el-input v-model="nowEditMenu.miniUrl"></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Preservation @preservation="Preservation"></Preservation>

    <!-- 查看更多弹框 -->
    <el-dialog title="查看更多" :visible.sync="lookMoreFlag" width="700px">
      <el-form ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="公众号名称:" prop="name">
          {{ dataInfo.name }}
          <div class="tips">
            公众号名称可通过微信认证进行修改,改名请前往
            <el-button type="text">微信认证</el-button>
            中重新认证
          </div>
        </el-form-item>
        <el-form-item label="公众号头像:" prop="name">
          <el-image :src="dataInfo.logo"></el-image>
        </el-form-item>
        <el-form-item label="公众号二维码:" prop="name">
          <div class="tips">
            下载公众号二维码或带有搜一搜标识的二维码,用于推广与分享公众号
            <el-button type="text" @click="downloadQrCode">下载二维码</el-button>
          </div>
        </el-form-item>
        <el-form-item label="微信号:" prop="name">
          {{ dataInfo.account || '--' }}
          <div class="tips">微信号是公众号的唯一标识,设置后,用户还可以通过微信号搜索到公众号</div>
        </el-form-item>
        <el-form-item label="公众号类型:" prop="name">服务号</el-form-item>
        <!-- <el-form-item label="主体信息:" prop="name"></el-form-item>
        <el-form-item label="认证情况:" prop="name"></el-form-item> -->
        <el-form-item label="微信开放平台ID:" prop="name">{{ dataInfo.key }}</el-form-item>
      </el-form>
    </el-dialog>

    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import CdSteps from '@/components/Steps/index';
import Steps from '@/components/Steps/stepsComponent.vue';
import ToPage from '@/views/addPages/toPage/index.vue';
import Preservation from '@/components/preservation';
import _ from 'lodash';
// 切换小程序时使用 调用绑定的小程序信息 并查看菜单中是否有appid
export default {
  components: {
    CdSteps,
    Steps,
    ToPage,
    Preservation,
  },
  data () {
    return {
      dataInfo: {},
      lookMoreFlag: false,
      button: [],
      // 当前选中的一级菜单索引
      firstMenuActive: 0,
      // 当前选中的二级菜单索引
      secondMenuActive: 0,
      // 当前正在操作的菜单
      nowEditMenu: {},
      nowEditMiniStep: 0,
      // 当前是否选中二级菜单
      isSecondMenu: false,

      // 已关联的小程序信息
      miniAppInfo: {},
      miniAppLogo: '',
      // 选择小程序路径弹框
      toPageFlag: false,
    };
  },
  computed: {
    secondMenuTop () {
      let subArr = this.button[this.firstMenuActive].sub_button || [];
      let top = subArr.length * 50;
      if (subArr.length < 5) top += 50;
      return -top + 'px';
    },
    secondMenuWidth () {
      let width = 0;
      let num = 1;
      if (this.button.length > 0) {
        num = this.button.length < 3 ? this.button.length + 1 : 3;
        width = 365 / num;
      }

      return width + 'px';
    },
  },
  methods: {
    // 定位当前正在操作的菜单
    getNowEditMenu (flag) {
      this.isSecondMenu = flag;
      this.nowEditMenu = this.isSecondMenu ? this.button[this.firstMenuActive].sub_button[this.secondMenuActive] : this.button[this.firstMenuActive] ? this.button[this.firstMenuActive] : {};
    },
    downloadQrCode () {
      window.open(this.dataInfo.qrcode_url);
    },
    // 获取菜单列表
    getMenu () {
      this.$axios.post(this.$api.set.officialAccountSet.getMenucz).then(res => {
        if (res.code === 0) {
          if (res.result.data_info?.button.length) {
            res.result.data_info.button.map(item => {
              item.sub_button = item.sub_button || [];
              item.editName = item.name;
              if (item.type === 'view') {
                item.h5Url = item.url;
              } else if (item.type === 'miniprogram') {
                item.miniUrl = item.url;
              }
              item.sub_button.map(sub => {
                sub.editName = sub.name;
              });
            });
            this.button = res.result.data_info.button;
            this.getNowEditMenu();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 添加一级菜单
    addFirstMenu () {
      if (this.button.length >= 3) {
        this.$message.warning('最多只能添加3个菜单');
        return;
      }
      this.button.push({ name: '菜单名称', editName: '菜单名称', type: 'click', sub_button: [] });
    },
    // 添加二级菜单
    addSecondMenu () {
      if (this.button[this.firstMenuActive].sub_button.length >= 5) {
        this.$message.warning('一个一级菜单只能添加五个二级菜单');
        return;
      }
      this.button[this.firstMenuActive].sub_button.push({ name: '子菜单名称', editName: '子菜单名称', type: 'click' });
      // 选中刚生成的二级菜单
      this.secondMenuActive = 0;
      this.getNowEditMenu(true);
    },
    //选择一级菜单
    activeFirst (index) {
      this.firstMenuActive = index;
      // 重置二级菜单索引
      this.secondMenuActive = 0;
      this.getNowEditMenu(false);
    },
    // 选择二级菜单
    activeSecond (index) {
      this.secondMenuActive = index;
      this.getNowEditMenu(true);
    },
    // 检测输入框输入的字节长度
    checkLength (str) {
      let nowMenu = this.isSecondMenu ? this.button[this.firstMenuActive].sub_button[this.secondMenuActive] : this.button[this.firstMenuActive];
      let max = this.isSecondMenu ? 16 : 8;
      let len = 0;
      for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len++;
        }
      }
      if (len > max) {
        this.$message.warning('长度超出限制');
        nowMenu.editName = nowMenu.name;
      } else {
        nowMenu.name = nowMenu.editName;
      }
    },
    // 页面加载时获取小程序和关联信息
    siteMiniAppInfo () {
      this.$axios
        .post(this.$api.set.wxApp)
        .then(res => {
          if (res.code === 0) {
            let result = res.result || {};
            if (result.appstatus == 6 || result.version_online) {
              this.miniAppLogo = result.logo;
              this.nowEditMiniStep = 1;
              return this.$axios.post(this.$api.set.officialAccountSet.getLinkMiniprogram);
            } else {
              return false;
            }
          } else {
            this.$message.errro(res.msg);
            return false;
          }
        })
        .then(res => {
          if (res?.result) {
            this.miniAppInfo = res.result;
            this.nowEditMiniStep = 2;
          }
        });
    },
    // 点击去授权按钮
    FirstStepFn () {
      this.$confirm('请确认是否成功授权并发布小程序！！！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.checkIsHaveMini();
        })
        .catch(() => {
          this.checkIsHaveMini();
        });
      const { href } = this.$router.resolve({
        path: `/shopManagement/wechatEcology`,
      });
      window.open(href, '_blank');
    },
    // 检测是否授权小程序
    checkIsHaveMini () {
      let { result } = this.selectMiniAppStatus();
      result = result || {};
      if (result.appstatus == 6 || result.version_online) {
        this.this.$message({
          type: 'success',
          message: '授权成功',
        });
      } else {
        this.$message({
          type: 'warning',
          message: '暂未授权',
        });
      }
    },
    // 获取小程序授权信息
    async selectMiniAppStatus () {
      return await this.$axios.post(this.$api.set.wxApp);
    },
    //点击第二步按钮
    SecondStepFn () {
      this.$confirm('请确认是否成功关联小程序！！！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.checkIsRelationApp();
        })
        .catch(() => {
          this.checkIsRelationApp();
        });
      window.open('https://mp.weixin.qq.com');
    },
    checkIsRelationApp () {
      let { result } = this.getLinkMiniprogram();
      if (result.length) {
        this.miniAppInfo = result[0];
        this.nowEditMiniStep = 2;
        this.this.$message({
          type: 'success',
          message: '关联成功',
        });
      } else {
        this.this.$message({
          type: 'success',
          message: '暂未关联',
        });
      }
    },
    // 获取关联的小程序信息
    async getLinkMiniprogram () {
      return await this.$axios.post(this.$api.set.officialAccountSet.getLinkMiniprogram);
    },
    // 选择跳转路径
    choosePage (index) {
      this.toPageFlag = true;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes (item) {
      this.nowEditMenu.pagepath = item.wxroute;
      this.toPageFlag = false;
    },
    // 删除菜单
    deleteMenu () {
      this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.isSecondMenu) {
            // 删除二级菜单
            this.button[this.firstMenuActive].sub_button.splice(this.secondMenuActive, 1);
            if (this.button[this.firstMenuActive].sub_button.length === 0) {
              this.$set(this.button[this.firstMenuActive], 'type', 'click');
            }
          } else {
            //删除一级菜单
            this.button.splice(this.firstMenuActive, 1);
            this.firstMenuActive -= 1;
          }
          this.secondMenuActive = 0;
          this.getNowEditMenu(false);
        })
        .catch(() => { });
    },
    // 保存菜单
    Preservation () {
      if (!this.button.length) {
        this.$message.warning('请添加菜单');
        return;
      }
      let data_is_ok = true;
      let pamarm = [];
      let button = _.cloneDeep(this.button);
      for (let i = 0; i < button.length; i++) {
        let obj = {
          type: button[i].type,
        };
        // 如果有子级菜单
        if (button[i].sub_button?.length) {
          if (!button[i].name) {
            // 此一级菜单数据不完整
            this.firstMenuActive = i;
            // 重置二级菜单索引
            this.secondMenuActive = 0;
            this.getNowEditMenu(false);
            data_is_ok = false;
            break;
          } else {
            obj = {
              name: button[i].name,
              sub_button: [],
            };
          }
          for (let j = 0; j < button[i].sub_button.length; j++) {
            let sub_obj = {
              type: button[i].sub_button[j].type,
            };
            let sub_flag = this.checkData(button[i].sub_button[j], sub_obj);
            if (sub_flag) {
              // 此一级菜单数据不完整
              this.firstMenuActive = i;
              // 重置二级菜单索引
              this.secondMenuActive = j;
              this.getNowEditMenu(true);
              data_is_ok = false;
              break;
            }
            obj.sub_button.push(sub_obj);
          }
        } else {
          let flag = this.checkData(button[i], obj);
          if (flag) {
            // 此一级菜单数据不完整
            this.firstMenuActive = i;
            // 重置二级菜单索引
            this.secondMenuActive = 0;
            this.getNowEditMenu(false);
            data_is_ok = false;
            break;
          }
        }
        pamarm.push(obj);
      }
      if (!data_is_ok) return;
      console.log(pamarm);
      this.$axios.post(this.$api.set.officialAccountSet.createMenu, { button: pamarm }).then(res => {
        if (res.code === 0) {
          this.$message.success('设置成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 保存时检测数据是否合法
    checkData (data, obj) {
      // click 类型菜单
      if (data.type == 'click') {
        if (!data.name || !data.key) {
          this.$message.warning('请将该菜单数据补充完整!');
          return true;
        } else {
          obj.name = data.name;
          obj.key = data.key;
        }
      }
      // view 类型菜单
      if (data.type == 'view') {
        if (!data.name || !data.h5Url) {
          this.$message.warning('请将该菜单数据补充完整!');
          return true;
        } else {
          obj.name = data.name;
          obj.url = data.h5Url;
        }
      }
      // 小程序类型
      if (data.type == 'miniprogram') {
        if (!data.name || !data.miniUrl || !data.pagepath) {
          this.$message.warning('请将该菜单数据补充完整!');
          return true;
        } else {
          obj.name = data.name;
          obj.url = data.miniUrl;
          obj.pagepath = data.pagepath;
          obj.appid = this.miniAppInfo.appid;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding: 20px 20px 80px 20px !important;
  font-size: 14px;

  .showInfo {
    display: flex;

    .infoTitle {
      width: 120px;
      flex-shrink: 0;
      text-align: right;
      margin-right: 10px;
      line-height: 40px;
    }
  }

  .tips {
    font-size: 12px;
    color: #939496;
  }

  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;

    .subTitle {
      color: #66b1ff;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
  }
}

.el-form {
  .el-form-item {
    margin-bottom: 0px !important;

    .el-image {
      width: 70px;
      height: 70px;
    }

    .tips {
      line-height: 20px !important;
    }
  }
}

.editingArea {
  display: flex;

  .phoneArea {
    width: 420px;
    height: 700px;
    flex-shrink: 0;
    background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/publicPhoneBg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .menuBox {
      width: calc(100% - 55px);
      height: 50px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;

      .menuActive {
        color: #2e75f5 !important;
        border: 1px #2e75f5 solid !important;
        border-left: 1px #2e75f5 solid !important;
        border-top: 1px #2e75f5 solid !important;
      }

      &>div {
        color: #333;
        flex: 1;
        height: 100%;
        border: 1px solid #e3e3e3;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:first-child) {
          border-left: none;
        }
      }

      .childMenu {
        position: absolute;
        width: 100%;
        color: #333;
        background-color: #fff;

        &>div {
          width: 100%;
          height: 50px;
          border: 1px solid #e3e3e3;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          &:not(:first-child) {
            border-top: none;
          }
        }
      }
    }
  }

  .operationArea {
    margin-left: 20px;
    flex: 1;
    height: 700px;
    background-color: #f4f6fa;
    padding: 10px 20px;

    .menuTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e7e7eb;
    }

    .menuBot {
      .top {
        color: #9a9a9a;
      }

      .mt10 {
        margin-top: 10px;
      }

      .ml10 {
        margin-left: 10px;
      }

      .menuItem {
        width: 100%;
        display: flex;
        margin-top: 20px;
        line-height: 40px;

        .title {
          width: 120px;
          flex-shrink: 0;

          text-align: right;
        }

        .right {
          width: calc(100% - 120px);
        }

        .el-input {
          width: 200px;
        }

        .area {
          width: 100%;
          background-color: #fff;
          height: 485px;
          padding: 20px;

          .view {
            color: #9a9a9a;
          }
        }
      }
    }
  }
}

.showMiniAppInfo {
  .appInfo {
    display: flex;
    align-items: center;

    img {
      width: 70px;
      height: 70px;
    }
  }
}
</style>
